<template>
  <div class="p-BizIndex">
    <van-tabs
      v-model="active"
      @change="onChange"
      line-width="60"
      line-height="6"
      sticky
    >
      <van-tab
        v-for="({ title, name }, $index) in tabs"
        :title="title"
        :name="name"
        :key="$index"
      >
        <biz-list />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { BizList } from './tab-components'
import { BIZ_TYPE } from '@/config/constant'

export default {
  data () {
    const tabs = []

    for (const key in BIZ_TYPE) {
      tabs.push({
        title: BIZ_TYPE[key],
        name: key
      })
    }

    return {
      active: tabs[0].name,
      tabs
    }
  },
  methods: {
    onChange (name) {
      this.$router.push({
        name: this.$route.name,
        query: { type: name }
      })
    }
  },
  components: {
    BizList
  },
  created () {
    const active = this.$route.query.type

    if (active === undefined) {
      this.$router.replace({
        name: this.$route.name,
        query: Object.assign({}, this.$route.query, {
          type: this.active
        })
      })
    }
    else if (active !== this.active) {
      this.active = active
    }
  }
}
</script>

<style lang="less">
.p-BizIndex {
  min-height: 100vh;
  padding-bottom: @tabbar-height;

  .van-sticky--fixed {
    background-color: #fff;
  }

  .van-tabs__wrap {
    width: 75%;
    margin: 0 auto;

    &:after {
      content: none;
    }
  }

  .van-tabs__line {
    bottom: 50%;
    margin-bottom: -2px;
    background-color: #B4B4B4;
    border-radius: 0 !important;
    opacity: .5;
  }

  .van-tabs__content {
    background-color: #F7F8FA;
    padding-bottom: 50px;
    min-height: calc(100vh - 44px);
  }

  .van-tab__pane {
    padding: 20px;
  }

  .CardNews {
    background-color: #fff;

    &.art {
      padding: 10px;
      border-radius: 6px;
      position: relative;

      .van-image {

        img {
          border-radius: 6px;
        }
      }

      .context {
        width: calc(100% - 120px);
        display: inline-block;
        vertical-align: top;
        padding: 0 0 0 14px;

        .t {
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }

        .t + p {
          position: absolute;
          bottom: 0;
          right: 14px;
        }
      }
    }

    &.topic {
      border-radius: 6px;

      .context {
        padding: 14px 18px 6px;

        .t {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &.cooperation {
      border-radius: 6px;

      .context {
        position: absolute;
        bottom: 2px;
        left: 8px;
        right: 8px;
        background-color: initial;
        z-index: 1;

        .t {
          color: #fff;
          word-break: break-all;

          & + p {
            display: none;
          }
        }
      }
    }
  }
}
</style>