<template>
  <div class="p-Biz">
    <a-spin :spinning="spinning">
      <div class="top-left--btn">
        <van-icon class-prefix="icon-artalliance" name="left" @click="$router.push({ name: 'biz-list' })" />
      </div>
      <div class="top-right--btn">
        <van-icon class-prefix="icon-artalliance" name="share" @click="shareVisible = true" />
        <art-widget-share v-model="shareVisible" />
      </div>
      <van-image class="topImage" width="100vw" height="200px" fit="cover" :src="localData.imgUrl" />
      <div class="block">
        <h2>{{ localData.title }}</h2>
        <p class="sub">#{{ bizType }}<span class="pull-right">{{ localData.createTime | fromNow }}发布</span></p>
        <van-divider />
      </div>
      <template v-if="$route.params.type === 'topic'">
        <div class="block toJoin">
          <div class="t">
            <p>{{ localData.location }}</p>
            <p>{{ localData.beginTime | dayjs('YYYY.M.D A h:mm') }} 至 {{ localData.endTime | dayjs('YYYY.M.D A h:mm') }}</p>
            <p>{{ localData.remark }}</p>
          </div>
          <span class="b">
            <van-button
              type="info"
              size="small"
              @click="openWindow(localData.joinLink)"
            >我要参加</van-button>
          </span>
          <van-divider />
        </div>
      </template>
      <div class="block">
        <div class="content" v-filter-html="localData.content"></div>
        <van-divider />
        <p class="sub" style="margin-top: 1em; padding-bottom: 1em;" v-if="$route.params.type === 'art'">本文版权属于不一致选，禁止转载。如有需要，请联系我们。</p>
      </div>
      <template v-if="localData.relationOpus">
        <div class="block grey">
          <h3>相关作品</h3>
          <van-row gutter="20">
            <van-col
              v-for="opus in localData.relationOpus"
              @click="$router.push({ name: 'art', params: { id: opus.opusId } })"
              span="12"
              :key="opus.id"
            >
              <art-card-art
                :name="opus.title"
                :type="opus.opusType"
                width="100%"
                height="120"
                :src="opus.thumbnailUrl"
              />
            </van-col>
          </van-row>
          <van-divider />
        </div>
      </template>
      <art-widget-logo style="padding-top: 26px; padding-bottom: 26px" />
    </a-spin>
  </div>
</template>

<script>
import request from '@/api/request'
import utils from '@/utils'
import documentTitle from '@/mixins/document-title'
import { requestMap } from './tab-components'
import { BIZ_TYPE } from '@/config/constant'

export default {
  mixins: [documentTitle],
  data () {
    const type = this.$route.params.type
    const { id } = requestMap[type]
    const asyncFetchData = request[id] || (() => Promise.resolve())

    return {
      asyncFetchData,
      localData: {},
      shareVisible: false,
      spinning: true
    }
  },
  computed: {
    bizType () {
      const type = this.$route.params.type

      if (type) {
        return BIZ_TYPE[type]
      }

      return ''
    }
  },
  watch: {
    '$route' (val) {
      this.fetchData(val.params.id)
    }
  },
  methods: {
    fetchData (id) {
      if (id !== undefined) {
        // TODO: types
        this.spinning = true
        this.asyncFetchData({ id }).then(res => {
          this.localData = res && res.news || {}
          this.spinning = false

          const { title } = this.localData

          if (title !== undefined) {
            this.changeTitle(`不一致选 - ${title}`)
          }
        })
      }
    },
    openWindow (url) {
      if (url) {
        utils.openWindow(url)
      }
    }
  },
  created () {
    this.fetchData(this.$route.params.id)
  }
}
</script>

<style lang="less">
.p-Biz {
  padding-bottom: @tabbar-height;
  min-height: 100vh;
  background-color: #F6F6F7;

  .topImage {
    display: block;
    max-width: 720px;
    height: auto !important;

    & + .block {
      padding-top: 20px;
    }
  }

  .sub {
    color: @primary-color-grey;
    font-size: 12px;
  }

  .toJoin {
    position: relative;

    .t {
      width: calc(100% - 80px - 20px);

      p {
        color: @gray-8;
        font-size: 14px;
        margin-bottom: .5em;
      }
    }

    .b {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      margin-top: -.5em;
    }

    .van-button {
      background-image: linear-gradient(147deg, #606060 7%, #040404 100%);
      border-radius: 2px;
      width: 80px;
      height: 32px;
      line-height: 30px;
    }
  }

  .block {
    background-color: #fff;
    padding: 16px 20px 0;

    .van-divider {
      margin-bottom: 0;
    }

    &.grey {
      background-color: #F6F6F7;
    }
  }
}
</style>