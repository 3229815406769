import {
  DEFAULT_DOCUMENT_TITLE
} from '@/config/constant'

export default {
  methods: {
    changeTitle (str) {
      document.title = str
    }
  },
  beforeRouteLeave (to, from, next) {
    document.title = DEFAULT_DOCUMENT_TITLE
    next()
  }
}
